import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../api';
import withUserInfos from '../HOC/withUserInfos';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Container, Link, TextField, Typography } from '@mui/material';
import { DataGrid, frFR } from '@mui/x-data-grid';
import { mapBooleanToSortedSelectOptions } from '../common/helper/gridFormatterHelper';
import RolesEnum from '../common/enum/RolesEnum';

const columns = [
  {
    field: 'firstname',
    headerName: 'Nom',
    minWidth: 250,
    renderCell: (params) => (
      <Link href={`/user/${params.row.id}`}>{`${params.row.firstname} ${params.row.lastname}`}</Link>
    ),
  },
  { field: 'email', headerName: 'Courriel', minWidth: 300 },
  {
    field: 'roles',
    headerName: 'Roles',
    minWidth: 250,
    valueFormatter: ({ value }) => value.map((data) => RolesEnum[data.name]).join(', '),
    sortable: false,
  },
  {
    field: 'isRestricted',
    headerName: 'Resteint',
    minWidth: 200,
    type: 'singleSelect',
    valueOptions: mapBooleanToSortedSelectOptions('Oui'),
    valueFormatter: (params) => (params.value ? 'Oui' : 'Non'),
  },
];

function UsersPage({ access_token }) {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    queryUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryUsers = () => {
    getAllUsers(access_token)
      .then((res) => {
        res.map((user) => (user.id = user._id));
        setUsers(res);
      })
      .catch((e) => console.log(e));
  };

  const handleOnSelect = (e, value) => {
    navigate(`/user/${value._id}`);
  };

  if (!users) {
    return <h2>Chargement...</h2>;
  }

  const options = users.map((user) => {
    const firstLetter = user.firstname[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      label: `${user.firstname} ${user.lastname}`,
      ...user,
    };
  });

  return (
    <Container>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        {users.length} utilisateurs
      </Typography>
      <Autocomplete
        onChange={handleOnSelect}
        noOptionsText={'Aucun résultat'}
        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.label}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label="Rechercher un utilisateur" />}
      />

      <Box
        sx={{
          marginY: '25px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '70VH',
          height: '70VH',
          zIndex: '-1',
        }}
      >
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'firstname', sort: 'asc' }],
            },
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          rows={users}
          columns={columns}
        />
      </Box>
    </Container>
  );
}

export default withUserInfos(UsersPage);
