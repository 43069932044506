import React, { useEffect, useState } from 'react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { deleteSite, getSite } from '../api';
import { SiteForm } from '../components';

import '../styles/fiches.css';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Link } from '@mui/material';
import ConfirmationDialog from '../common/components/ConfirmationDialog';

function SitePage() {
  const { id = '' } = useParams();

  const [name, setName] = useState('');
  const [isOpenDialogConfirmation, setIsOpenDialogConfirmation] = useState(false);
  const [url_frontend, setUrl_frontend] = useState('');
  const [url_backend, setUrl_backend] = useState('');
  const [apis_path, setApis_path] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getSiteInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSiteInfos = () => {
    getSite(id).then((res) => {
      const { name, url_frontend, url_backend, apis_path } = res;
      setName(name);
      setUrl_frontend(url_frontend);
      setUrl_backend(url_backend);
      setApis_path(apis_path);
    });
  };
  const deleteSelf = () => {
    deleteSite(id).then(() => {
      navigate('/sites');
    });
  };

  if (!name) {
    return <h2>Chargement...</h2>;
  }

  return (
    <Container className="site-page">
      <div className="actions-btns">
        <Link component={RouterLink} to="/sites" underline="none">
          <Button variant="contained" sx={{ display: 'block' }}>
            Retour aux sites
          </Button>
        </Link>
        <ConfirmationDialog
          isOpen={isOpenDialogConfirmation}
          title="Confirmation"
          onConfirm={deleteSelf}
          onCancel={() => setIsOpenDialogConfirmation(false)}
        >
          Êtes-vous certain de vouloir supprimer le site de la base de donnée et de Algolia?
        </ConfirmationDialog>
        <Button onClick={() => setIsOpenDialogConfirmation(true)} color="warning" variant="contained">
          Supprimer
        </Button>
      </div>
      <header className="site-page-header">
        <h2>{name}</h2>
        <h4>
          Backend :<a href={url_backend}>{url_backend}</a>
        </h4>
        <h4>
          Frontend: <a href={url_frontend}>{url_frontend}</a>
        </h4>
      </header>
      {apis_path && apis_path.length ? (
        <div className="apis">
          <h3>Url d'APIS</h3>
          <ul className="apis">
            {apis_path.map((element, i) => (
              <li className="api" key={'api-' + i}>
                <h4>
                  <a href={url_backend + element}>{url_backend + element}</a>
                </h4>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        'Loading API'
      )}

      <SiteForm id={id} callback={getSiteInfos} />
    </Container>
  );
}
export default SitePage;
