import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

const getAllSites = () =>
  axios
    .get(`${api_url}/sites`)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const createSite = (args) =>
  axios
    .post(`${api_url}/sites`, args)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const getSite = (site_id) =>
  axios
    .get(`${api_url}/site/${site_id}`)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const updateSite = (args) =>
  axios
    .put(`${api_url}/site/${args['_id']}`, args)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const deleteSite = (site_id) =>
  axios
    .delete(`${api_url}/site/${site_id}`)
    .then((response) => 'Deleted')
    .catch((e) => errorCatcher(e));

const getUserAnthologies = (user_id) =>
  axios
    .get(`${api_url}/anthologies/user/${user_id}`)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const createAnthologie = (args) =>
  axios
    .post(`${api_url}/anthologies`, args)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const getAnthologie = (anthologie_id) =>
  axios
    .get(`${api_url}/anthologie/${anthologie_id}`, { params: { showRestricted: true } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const updateAnthologie = (args) =>
  axios
    .put(`${api_url}/anthologie/${args['_id']}`, args)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const deleteAnthologie = (anthologie_id) =>
  axios
    .delete(`${api_url}/anthologie/${anthologie_id}`)
    .then((response) => 'Deleted')
    .catch((e) => errorCatcher(e));

const deleteAnthologiesWithoutCreator = () =>
  axios
    .delete(`${api_url}/anthologie/no_creator`)
    .then((response) => 'Deleted')
    .catch((e) => errorCatcher(e));

const loginUser = (args) =>
  axios
    .post(`${api_url}/user/signin`, args)
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const testUserToken = (access_token) =>
  axios
    .get(`${api_url}/user/ismod`, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => console.log(e))
    .catch((e) => errorCatcher(e));

const getAllUsers = (access_token) =>
  axios
    .get(`${api_url}/users`, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const getUser = (user_id, access_token) =>
  axios
    .get(`${api_url}/user/${user_id}`, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const updateUser = (user, access_token) =>
  axios
    .put(`${api_url}/user/${user._id}`, user, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const getAllRoles = (isAdmin) =>
  axios
    .get(`${api_url}/roles`, { headers: { isAdmin } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const deleteUser = (user_id, access_token) =>
  axios
    .delete(`${api_url}/user/${user_id}`, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const resetDB = (access_token) =>
  axios
    .get(`${api_url}/scraper/reset`, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const startScrapper = (access_token) =>
  axios
    .get(`${api_url}/scraper`, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((e) => errorCatcher(e));

const errorCatcher = (error) => {
  if (error.response) {
    return { error: error.response.data };
  } else if (error.request) {
    return { error: error.request };
  } else {
    return { error: error.message };
  }
};

export {
  getAllSites,
  createSite,
  getSite,
  updateSite,
  deleteSite,
  getUserAnthologies,
  createAnthologie,
  getAnthologie,
  updateAnthologie,
  deleteAnthologie,
  deleteAnthologiesWithoutCreator,
  loginUser,
  testUserToken,
  getAllUsers,
  getUser,
  updateUser,
  getAllRoles,
  deleteUser,
  startScrapper,
  resetDB,
};
