import React, { useState } from 'react';
import { AppBar, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import withUserInfos from '../HOC/withUserInfos';
import { Link as RouterLink } from 'react-router-dom';

const pagesUrls = [
  { name: 'Usagers', url: '/' },
  { name: 'Sites', url: '/sites', adminOnly: true },
];

function Header({ isAdmin, isLoggedIn, signout }) {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleLogout = () => {
    signout();
  };

  const pages = pagesUrls.filter((page) => {
    return !page.adminOnly || (page.adminOnly && isAdmin());
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  if (!isLoggedIn) {
    return <></>;
  }

  return (
    <AppBar sx={{ marginBottom: '40px' }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ENCODAGE
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={page.name + i} onClick={handleCloseNavMenu}>
                  <Link component={RouterLink} to={`${page.url}`} underline="none">
                    <Typography color="black" textalign="center">
                      {page.name}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography color="black" onClick={handleLogout} textalign="center">
                  Déconnexion
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ENCODAGE
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, i) => (
              <Link key={page.name} component={RouterLink} to={page.url} underline="none">
                <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Button sx={{ color: 'white' }} onClick={handleLogout} textalign="center">
              Déconnexion
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default withUserInfos(Header);
