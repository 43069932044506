import React, { Component } from 'react';
import { LoginForm } from '../components';
export default class LoginPage extends Component {
  render() {
    return (
      <main className="login-page">
        <LoginForm callback={this.props.callback} />
      </main>
    );
  }
}
