import React, { useEffect, useState } from 'react';
import { getAllSites } from '../api';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
function SitesList() {
  const [sites, setSites] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const handleFetch = () => {
    getAllSites().then((res) => {
      setLoaded(true);
      setSites(res);
      setIsloading(false);
    });
  };

  useEffect(() => {
    if (!isLoading && !loaded) {
      setIsloading(true);
      handleFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <ul className="sites-list">
      {sites.map((element, i) => (
        <li style={{ marginBottom: '0.25rem' }} key={`site-${i}`}>
          <Link sx={{ fontSize: 'large', mb: 1 }} color="secondary" component={RouterLink} to={'/site/' + element._id}>
            {element.name}
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    ''
  );
}

export default SitesList;
