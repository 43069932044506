import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

export const appTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#737373',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#FF7C44',
    },
    warning: {
      main: '#CA4208',
    },
  },
  typography: {
    fontFamily: `Inter`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  frFR,
});
