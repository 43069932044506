import React, { Component } from 'react';
import { createSite, getSite, updateSite } from '../api';
import '../styles/form.css';
import { Button } from '@mui/material';
export default class SiteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_name: '',
      url_frontend: '',
      url_backend: '',
      id: this.props.id ? this.props.id : null,
      updated: false,
      api_urls: [],
    };
  }
  componentDidMount = () => {
    if (this.props.id) this.getSiteInfos();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.id !== this.props.id) this.getSiteInfos();
  };

  getSiteInfos = () => {
    getSite(this.props.id).then((site) => {
      this.setState({
        site_name: site.name,
        url_backend: site.url_backend,
        url_frontend: site.url_frontend,
        id: site._id,
        api_urls: site.apis_path,
      });
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removeApi = (e) => {
    const i = e.target.id.replace(/remove-/, '');
    const api_urls = [...this.state.api_urls];
    api_urls.splice(i, 1);
    this.setState({ api_urls });
  };
  handleApiChange = (e) => {
    let id = e.target.id;
    if (id === 'api-new') {
      this.setState({ api_urls: [...this.state.api_urls, e.target.value] }, () => {
        id = 'api-' + (this.state.api_urls.length - 1);
        const input = document.getElementById(id);
        input.focus();
      });
    } else {
      const i = id.replace(/api-/, '');
      this.setState((state) => {
        const api_urls = state.api_urls.map((item, j) => {
          if (j === i) {
            return e.target.value;
          } else {
            return item;
          }
        });

        return {
          api_urls,
        };
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    //Var
    const { site_name, url_frontend, url_backend, id, api_urls } = this.state;
    //Create/Update the site
    if (id) {
      //if id update
      //Get the data to send
      const data = {
        name: site_name,
        url_backend: url_backend,
        url_frontend: url_frontend,
        _id: id,
        apis_path: api_urls,
      };
      const site = updateSite(data);
      site.then((res) => {
        this.setState({
          site_name: res.name,
          url_backend: res.url_backend,
          url_frontend: res.url_frontend,
          api_urls: res.apis_path,
          id: res._id,
        });
        if (this.props.callback) this.props.callback();
      });
    } else {
      //if not add
      //Get the data to send
      const data = {
        name: site_name,
        url_backend: url_backend,
        url_frontend: url_frontend,
        apis_path: api_urls,
      };
      createSite(data)
        .then((res) => {
          this.setState({
            site_name: '',
            site_url: '',
            url_backend: '',
            url_frontend: '',
            api_urls: [],
          });
          if (this.props.callback) this.props.callback();
        })
        .catch((err) => console.log(err));
    }
  };
  render() {
    const { url_backend, url_frontend, site_name, api_urls, id } = this.state;
    return (
      <div className="site-informations-modifications">
        <form className="form" onSubmit={this.handleSubmit} id="site-form">
          <header className="site-form-header">
            <h2>{id ? 'Modification des Informations' : "Ajout d'un site"}</h2>
          </header>
          <span>
            <label>Nom du site: </label>
            <input id="formName" type="text" name="site_name" value={site_name} onChange={this.handleChange} required />
          </span>
          <span>
            <label>Url du site backend: </label>
            <input
              id="formUrl"
              type="text"
              name="url_backend"
              value={url_backend}
              onChange={this.handleChange}
              required
            />
          </span>
          <span>
            <label>Url du site frontend: </label>
            <input
              id="formUrl"
              type="text"
              name="url_frontend"
              value={url_frontend}
              onChange={this.handleChange}
              required
            />
          </span>
          <div className="api-urls">
            {api_urls.map((api_url, i) => (
              <span key={'api-' + i}>
                <label>Chemin de l'Api {i + 1}: </label>
                <input id={'api-' + i} type="text" name="api_url[]" value={api_url} onChange={this.handleApiChange} />
                <button id={`remove-${i}`} onClick={this.removeApi}>
                  X
                </button>
              </span>
            ))}
            <span>
              <label>Chemin d'une nouvelle api: </label>
              <input id="api-new" type="text" name="api_url[]" value="" onChange={this.handleApiChange} />
            </span>
          </div>
          <Button sx={{ mt: 2 }} variant="contained" type="submit" value="Submit" id="btn-submit">
            Soumettre
          </Button>
        </form>
      </div>
    );
  }
}
