import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function TextContent({ children }) {
  return (
    <DialogContent>
      <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{children}</DialogContentText>
    </DialogContent>
  );
}

function ConfirmationDialog({
  isOpen,
  title,
  onConfirm,
  onCancel,
  onClose = onCancel,
  cancelLabel,
  confirmLabel,
  children,
  confirmAccent = true,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {typeof children === 'string' && <TextContent>{children}</TextContent>}
      {Array.isArray(children) && (
        <>
          {children.map((text) => (
            <TextContent>{text}</TextContent>
          ))}
        </>
      )}
      {typeof children === 'function' && <TextContent>{children({})}</TextContent>}
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel || 'Cancel'}</Button>
        <Button onClick={onConfirm} {...(confirmAccent && { color: 'warning', variant: 'contained' })}>
          {confirmLabel || 'Confirmer'}{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
