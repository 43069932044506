import './styles/App.css';

import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import { SitePage, LoginPage, HomePage, UsersPage, UserInfoPage, AnthologiePage } from './pages';
import { Header } from './components';
import { testUserToken } from './api';
import SitesPage from './pages/Sites.page';
import { Box, Container } from '@mui/material';

function App({ cookies }) {
  const [accessToken, setAccessToken] = useState(cookies.get('accessToken') ? cookies.get('accessToken') : '');
  const [username, setUsername] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      setLoading(true);
      testAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const signout = () => {
    setAccessToken('');
    setLoading(false);
    cookies.remove('accessToken');
    cookies.remove('user_id');
    setIsLoggedIn(false);

    navigate('');
  };

  const testAccessToken = () => {
    testUserToken(accessToken).then((res) => {
      if (res && res.tokenIsValid) {
        setIsLoggedIn(true);
        setUsername(res.user.username);
        setLoading(false);
      } else {
        signout();
      }
    });
  };

  const setLoggedInfos = (args) => {
    setLoading(true);
    if (args.accessToken) {
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 7);
      cookies.set('accessToken', args.accessToken, {
        path: '/',
        expires: expiration,
      });
      setAccessToken(args.accessToken);
      setUsername(args.email);
    }
  };

  return loading ? (
    <Container className="App">
      <div className="loading-screen">
        <span>Chargement...</span>
      </div>
    </Container>
  ) : (
    <Box className="App">
      <Header isLoggedIn={isLoggedIn} username={username} signout={signout} />
      <Routes>
        <Route path="/site/:id" element={<SitePage />} />
        <Route path="/sites" element={<SitesPage />} />
        <Route path="/user/:userId" element={<UserInfoPage />} />
        <Route path="/anthologie/:anthoId" element={<AnthologiePage />} />
        <Route path="/" element={isLoggedIn ? <UsersPage /> : <HomePage />} />
        <Route path="/login" element={<LoginPage callback={setLoggedInfos} />} />
      </Routes>
    </Box>
  );
}
export default withCookies(App);
