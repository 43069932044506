import React, { useState } from 'react';
import { startScrapper, resetDB, deleteAnthologiesWithoutCreator } from '../api';

import { SitesList, SiteForm } from '../components';
import withUserInfos from '../HOC/withUserInfos';
import { Button, ButtonGroup } from '@mui/material';
import ConfirmationDialog from '../common/components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';

function SitesPage({ access_token }) {
  const [isOpenResetConfirmation, setIsOpenResetConfirmation] = useState(false);
  const [isOpenStartScrapperConfirmation, setIsOpenStartScrapperConfirmation] = useState(false);
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleScrappe = () => {
    startScrapper(access_token).then((res) => {});
    setIsOpenStartScrapperConfirmation(false);
  };

  const handleResetFiches = () => {
    resetDB(access_token).then((res) => {});
    setIsOpenResetConfirmation(false);
  };

  const deleteAnthoWithoutCreator = () => {
    deleteAnthologiesWithoutCreator().then((res) => console.log(res));
    setIsOpenDeleteConfirmation(false);
  };

  return (
    <main className="home-page">
      <div>
        <h2>Liste des sites</h2>
        <SitesList />
      </div>
      <SiteForm callback={() => navigate('/sites')} />
      <ButtonGroup variant="text" sx={{ mt: 4, mb: 6 }}>
        <Button color="warning" onClick={() => setIsOpenResetConfirmation(true)}>
          Supprimer les fiches
        </Button>
        <Button color="warning" onClick={() => setIsOpenStartScrapperConfirmation(true)}>
          Lancer le Scrapper APIS
        </Button>
        <Button color="warning" onClick={() => setIsOpenDeleteConfirmation(true)}>
          Supprimer les anthologies sans créateur
        </Button>
      </ButtonGroup>
      <ConfirmationDialog
        isOpen={isOpenResetConfirmation}
        title="Réinitialiser les fiches"
        onConfirm={handleResetFiches}
        onCancel={() => setIsOpenResetConfirmation(false)}
      >
        Êtes-vous certain de vouloir supprimer chaque fiche de la base de données? (DANGER)
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={isOpenStartScrapperConfirmation}
        title="Lancer le scrapper des APIS"
        onConfirm={handleScrappe}
        onCancel={() => setIsOpenStartScrapperConfirmation(false)}
      >
        Êtes-vous certain de vouloir lancer le scrapper pour parcourir chaque site?
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={isOpenDeleteConfirmation}
        title="Supprimer les anthologies sans créateur"
        onConfirm={deleteAnthoWithoutCreator}
        onCancel={() => setIsOpenDeleteConfirmation(false)}
      >
        Êtes-vous certain de vouloir supprimer les anthologies sans créateurs?
      </ConfirmationDialog>
    </main>
  );
}

export default withUserInfos(SitesPage);
