import React, { useEffect, useState } from 'react';
import { deleteUser, getAllRoles, getUser, getUserAnthologies, updateUser } from '../api';
import WithUserInfos from '../HOC/withUserInfos';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Link,
  List,
  TextField,
  Typography,
} from '@mui/material';
import ListItemLink from '../components/ListItemLink';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import '../styles/App.css';
import _ from 'lodash';
import LabelField from '../components/LabelField';
import RolesEnum from '../common/enum/RolesEnum';
import ConfirmationDialog from '../common/components/ConfirmationDialog';

function UserInfoPage({ access_token, isAdmin }) {
  const [user, setUser] = useState([]);
  const [roles, setRoles] = useState([]);
  const [user_anthologies, setUser_anthologies] = useState(false);
  const [isOpenDialogConfirmation, setIsOpenDialogConfirmation] = useState(false);
  const [edit_mode, setEdit_mode] = useState(false);

  const { userId = '' } = useParams();

  useEffect(() => {
    if (userId) {
      fetchUser(userId, access_token);
    }
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = (userId, accessToken) => {
    getUser(userId, accessToken).then((res) => {
      if (res.error) {
        console.log(res.error);
        return null;
      }
      setUser(res);
      fetchAnthologie(userId);
    });
  };

  const getRoles = () => {
    getAllRoles(isAdmin()).then((res) => setRoles(res));
  };

  const fetchAnthologie = (userId) => {
    getUserAnthologies(userId).then((res) => setUser_anthologies(res));
  };

  const setEditMode = () => {
    setEdit_mode(!edit_mode);
  };

  const handleDeleteUser = () => {
    if (window.confirm(`Êtes vous certains de vouloir supprimer ${user.firstname} ${user.lastname} ?`)) {
      deleteUser(user._id, access_token).then((res) => {
        if (res.error) {
          alert(res.error);
          return;
        }
        window.location = '/users';
      });
    }
  };

  const editUser = (e) => {
    e.preventDefault();
    updateUser(user, access_token).then((res) => {
      fetchUser(user._id, access_token);
      setEdit_mode(false);
    });
  };

  const handleChange = (evt) => {
    const event = evt.nativeEvent ?? evt;
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (target.type === 'checkbox' && name === 'roles') {
      const checked = target.checked;
      let array = user[name];
      const role = roles.find((role) => role._id === value);
      if (checked) {
        if (array.indexOf(value) <= -1) {
          array = [...array, role];
        }
      } else {
        const index = array.indexOf(array.find((role) => role._id === value));
        if (index > -1) {
          array.splice(index, 1);
        }
      }
      setUser({ ...user, [name]: array });
    } else if (target.type === 'checkbox' && name === 'restricted') {
      const checked = target.checked;
      setUser({ ...user, isRestricted: checked });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const isEmpty = (val) => {
    return val === undefined || val === null || val.length <= 0;
  };

  if (user.roles) {
    roles.map((role) => {
      role.checked = !isEmpty(user.roles.find((user_role) => user_role._id === role._id));
      return role;
    });
  }

  if (!user || user.length < 1) {
    return <h2>Chargement...</h2>;
  }

  return (
    <Container>
      <div className="actions-btns">
        <Link component={RouterLink} to={`/`} underline="none">
          <Button variant="contained" sx={{ display: 'block' }}>
            Retour à la recherche
          </Button>
        </Link>
      </div>
      <h1>Détail de l'usager</h1>
      {edit_mode ? (
        <form onSubmit={editUser} className="user-edit-form">
          {!user.roles.some((role) => role.name === 'admin') && (
            <Container sx={{ minWidth: '400px' }}>
              <FormGroup>
                <FormControlLabel
                  key={roles._id}
                  control={<Checkbox name="restricted" checked={user.isRestricted} onChange={handleChange} />}
                  label="Restreindre l'usager"
                  sx={{ color: 'red', justifyContent: 'center' }}
                />
              </FormGroup>
            </Container>
          )}
          <div>
            <TextField
              name="firstname"
              variant="standard"
              sx={{ my: 1, minWidth: '350px' }}
              value={user.firstname}
              onChange={handleChange}
              label="Prénom"
            />
          </div>
          <div>
            <TextField
              name="lastname"
              variant="standard"
              type="text"
              sx={{ my: 1, minWidth: '350px' }}
              value={user.lastname}
              onChange={handleChange}
              label="Nom de famille"
            />
          </div>
          <div>
            <TextField
              name="email"
              type="email"
              sx={{ my: 1, minWidth: '350px' }}
              variant="standard"
              value={user.email}
              onChange={handleChange}
              label="Courriel"
            />
          </div>
          <TextField
            name="biography"
            type="text"
            multiline
            sx={{ my: 1, minWidth: '350px' }}
            variant="standard"
            value={user.biography ?? ''}
            onChange={handleChange}
            label="Biographie"
          />

          <Container>
            <Box>
              <FormGroup row sx={{ justifyContent: 'center' }}>
                {user.roles
                  ? roles.map((role) => (
                      <FormControlLabel
                        key={roles._id}
                        control={
                          <Checkbox name="roles" checked={role.checked} value={role._id} onChange={handleChange} />
                        }
                        label={RolesEnum[role.name]}
                      />
                    ))
                  : ''}
              </FormGroup>
            </Box>
          </Container>
          <Button color="secondary" variant="contained" sx={{ my: 2, mr: 1 }} onClick={() => setEditMode(false)}>
            Annuler
          </Button>
          <Button color="secondary" variant="contained" sx={{ my: 2, mr: 1 }} type="submit">
            Modifier
          </Button>
          <Button
            color="warning"
            variant="contained"
            sx={{ my: 2, mr: 1 }}
            onClick={() => setIsOpenDialogConfirmation(true)}
          >
            Supprimer
          </Button>
          <ConfirmationDialog
            isOpen={isOpenDialogConfirmation}
            title="Confirmation"
            onConfirm={handleDeleteUser}
            onCancel={() => setIsOpenDialogConfirmation(false)}
          >
            Êtes-vous certain de vouloir supprimer cet usager? Cette action est irréversible et supprimera également les
            anthologies créées par cet usager.
          </ConfirmationDialog>
        </form>
      ) : (
        <Container id="user-infos">
          <Typography sx={{ color: 'red' }}>
            {user.isRestricted ? 'Cet usager est restreint, son contenu est invisible' : ''}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ p: '0 9px 0 9px', marginTop: 1 }}>
              <LabelField sx={{ minWidth: '350px' }} label={user.firstname} title="Prénom" />
            </Box>
            <Box sx={{ p: '0 9px 0 9px', marginTop: 1.5 }}>
              <LabelField sx={{ minWidth: '350px' }} label={user.lastname} title="Nom de famille" />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ p: '0 9px 0 9px', marginTop: 1.5 }}>
              <LabelField sx={{ minWidth: '350px' }} label={user.email} title="Courriel" />
            </Box>
            <Box id="user-roles" sx={{ p: '0 9px 0 9px', marginTop: 1.5 }}>
              <LabelField
                sx={{ minWidth: '350px' }}
                label={_.flatMap(user.roles, 'name')
                  .map((role) => RolesEnum[role])
                  .join(', ')}
                title="Roles"
              />
            </Box>
          </Box>
          <Button sx={{ marginTop: 2 }} color="secondary" variant="outlined" onClick={setEditMode}>
            Éditer
          </Button>
        </Container>
      )}
      {user_anthologies.length > 0 && !edit_mode && (
        <>
          <div className="anthology-list">
            <List aria-label="anthology-list" subheader={<h4>Anthologies</h4>} sx={{ mt: 1 }}>
              {user_anthologies.map((antho) => (
                <ListItemLink
                  key={antho._id}
                  to={`/anthologie/${antho._id}`}
                  primary={antho.name}
                  icon={<CollectionsBookmarkIcon />}
                />
              ))}
            </List>
          </div>
        </>
      )}
    </Container>
  );
}

export default WithUserInfos(UserInfoPage);
