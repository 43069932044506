import React, { useEffect, useState } from 'react';
import { testUserToken } from '../api';
import CookiesUtils from '../utils/CookiesUtils';

function withUserInfos(WrappedComponent) {
  function HOC({ ...props }) {
    const [user_id] = useState(CookiesUtils.getCookie('user_id') ? CookiesUtils.getCookie('user_id') : null);
    const [access_token] = useState(
      CookiesUtils.getCookie('accessToken') ? CookiesUtils.getCookie('accessToken') : null,
    );
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
      if (access_token && !isLoggedIn) {
        testUserToken(access_token).then((res) => {
          setUser(res.user);
          setIsLoggedIn(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isAdmin = () => {
      if (user && user.roles) {
        return !!user.roles.filter((el) => el.name === 'admin').length;
      }
    };

    //return original react component with additional props
    return (
      <WrappedComponent
        user={user}
        user_id={user_id}
        isAdmin={isAdmin}
        access_token={access_token}
        isLoggedIn={isLoggedIn}
        {...props}
      />
    );
  }

  return HOC;
}

export default withUserInfos;
