import React, { useState } from 'react';
import { Loading } from 'react-loading-dot';

import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loginUser } from '../api';
import '../styles/form.css';
import { useNavigate } from 'react-router-dom';

function LoginForm({ callback }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn();
  };
  const signIn = () => {
    //Get the data to send
    const data = {
      email,
      password,
    };
    setLoading(true);

    loginUser(data).then((res) => {
      if (res.message) {
        setError(res.message());
        setLoading(false);
      } else if (res.roles && res.roles.some((ai) => ['ROLE_ADMIN'].includes(ai) || ['ROLE_MODERATOR'].includes(ai))) {
        setLoading(false);
        if (callback) {
          callback(res);
          navigate('/');
        }
      } else {
        setLoading(false);
        setError("L'utilisateur n'est pas admin");
      }
    });
  };

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const loginForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="input">
          <div className="title">
            <FontAwesomeIcon icon={faEnvelope} />
            Courriel
          </div>
          <input
            className="text"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input">
          <div className="title">
            <FontAwesomeIcon icon={faLock} />
            Mot de passe
          </div>
          <input
            className="text"
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="input">
          <input type="submit" value="Me Connecter" disabled={!validateForm()} />
        </div>
      </form>
    );
  };

  return (
    <div className="my-account">
      <div className="container">
        <h2>Accéder à mon compte</h2>
        {error && <div className="error">{error}</div>}
        {loading ? (
          <div>
            <Loading background={'rgb(189,189,189)'} />
          </div>
        ) : (
          <div>
            <div className={`pages login-page`}>
              <div className={`page login show`}>{loginForm()}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginForm;
