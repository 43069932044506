import Cookies from 'universal-cookie';

class CookiesUtils {
  static setCookie = (key, value, options) => {
    const cookies = new Cookies();
    // Stuff to always do when setting a cookie
    return cookies.set(key, value, options);
  };
  static getCookie = (key) => {
    const cookies = new Cookies();
    // Stuff to always do when setting a cookie
    return cookies.get(key);
  };
  static remove = (key, options) => {
    const cookies = new Cookies();
    // Stuff to always do when setting a cookie
    return cookies.remove(key, options);
  };
}

export default CookiesUtils;
