import React from 'react';
import logo from '../img/logo.png';
import { Box } from '@mui/material';

export default function HomePage() {
  return (
    <main className="home-dev-page">
      <header className="page-header">
        <Box sx={{ maxWidth: '95%' }} component="img" alt="logo" src={logo} />
        <h4>Administration</h4>
      </header>
      <a href="/login">Se connecter</a>
    </main>
  );
}
