import React, { useEffect, useState } from 'react';
import { getAnthologie, updateAnthologie } from '../api';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Link, TextField } from '@mui/material';
import LabelField from '../components/LabelField';

function AnthologiePage() {
  const { anthoId = '' } = useParams();
  const [anthology, setAnthology] = useState(null);
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [isFeatured, setIsFeatured] = useState(true);
  const [name, setName] = useState('');
  const [editMode, setEditMode] = useState(false);

  const fetchAnthologie = () => {
    if (anthoId) {
      getAnthologie(anthoId).then((anthologie) => {
        setAnthology(anthologie);
      });
    }
  };

  useEffect(() => {
    fetchAnthologie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (anthology) {
      setName(anthology.name);
      setDescription(anthology.description);
      setIsPublic(anthology.is_public);
      setIsFeatured(anthology.is_featured);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anthology]);

  if (!anthology) {
    return <h2>Chargement...</h2>;
  }

  const { creator } = anthology;

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleOnSubmit = async () => {
    setAnthology({ ...anthology, name, description, is_public: isPublic, is_featured: isFeatured });
    const data = { ...anthology, name, description, is_public: isPublic, is_featured: isFeatured };
    await updateAnthologie(data);
    await getAnthologie(anthoId);
    toggleEditMode();
  };

  return (
    <Container>
      <div className="actions-btns">
        <Link component={RouterLink} to={`/user/${creator._id}`} underline="none">
          <Button variant="contained" sx={{ display: 'block' }}>
            Retour à l'usager
          </Button>
        </Link>
      </div>
      <h1>Détail de l'anthologie</h1>
      <TextField
        name="name"
        variant="standard"
        type="text"
        disabled={!editMode}
        multiline
        sx={{ my: 1, minWidth: '350px' }}
        value={name}
        onChange={(e) => setName(e.target.value)}
        label="Titre"
      />
      <Box>
        <TextField
          name="description"
          variant="standard"
          type="text"
          disabled={!editMode}
          multiline
          sx={{ my: 4, minWidth: '350px' }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
        />
      </Box>

      <Container sx={{ minWidth: '400px' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="isFeatured"
                disabled={!editMode}
                checked={isFeatured}
                onChange={(evt) => setIsFeatured(evt.target.checked)}
              />
            }
            label="En vedette"
            sx={{ justifyContent: 'center' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isPublic"
                disabled={!editMode}
                checked={isPublic}
                onChange={(evt) => {
                  setIsPublic(evt.target.checked);
                }}
              />
            }
            label="Publique"
            sx={{ justifyContent: 'center' }}
          />
        </FormGroup>
      </Container>
      <Box>
        <LabelField
          sx={{ my: 2, minWidth: '350px' }}
          label={creator.firstname + ' ' + creator.lastname}
          title="Créateur"
        />
      </Box>

      <Button color="secondary" variant="contained" sx={{ my: 2, mr: 1 }} onClick={toggleEditMode}>
        {!editMode ? 'Modifier' : 'Annuler'}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        sx={{ my: 2, mr: 1, visibility: !editMode ? 'hidden' : '' }}
        onClick={handleOnSubmit}
      >
        Confirmer
      </Button>
    </Container>
  );
}

export default AnthologiePage;
