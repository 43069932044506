import { TextField } from '@mui/material';

const style = {
  '& .MuiInput-input.Mui-disabled': {
    WebkitTextFillColor: 'black',
    color: 'black',
  },
};

function LabelField({ label, title = '', sx }) {
  return (
    <TextField
      variant="standard"
      disabled
      value={label}
      label={title}
      sx={{ ...style, ...sx }}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
}

export default LabelField;
